//import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import NavDropdown from 'react-bootstrap/NavDropdown'
import Logo from "../components/logo"
import "../stylesheets/style.scss"

const Header = ({ siteTitle }) => (

  <header>
    <div className="container-fluid">
      <div className="container">
        <div className="row">
          <div className="col">
              <Navbar bg="dark" variant="dark" expand="lg">
              <Navbar.Brand href="/">
                <Logo/>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <Nav.Link href="/">Home</Nav.Link>
                  <Nav.Link href="/web">Web</Nav.Link>
                  <Nav.Link href="/photos">Photos</Nav.Link>
                  <Nav.Link href="/design">Design</Nav.Link>
                  <NavDropdown title="About" id="nav-dropdown">
                  {/* <NavDropdown.Item eventKey="4.1" href="/about/me">Me</NavDropdown.Item> */}

        <NavDropdown.Item eventKey="4.1" href="/about/life">Life</NavDropdown.Item>
      </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>

      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
