/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, pageDescription, heroImage }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: pageDescription !=="" ? pageDescription : metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:url`,
          content: typeof window !== 'undefined' ? window.location.href : '',
        },
        {
          property: `og:description`,
          content: pageDescription !=="" ? pageDescription : metaDescription,
        },
        {
          property: `og:locale`,
          content: 'en_US',
        },
        {
          property: `og:site_name`,
          content: `jabercrombia`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: heroImage  !=="" ? heroImage :'//images.ctfassets.net/vzvml7le9kx6/6itw5y7YR1Hax3MIdq0qQ6/a422b2c3198b894cda88ead2c834a556/golden-close_0.jpg?w=2000&h=500&q=50&fit=fill',
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: pageDescription !=="" ? pageDescription : metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  heroImage:'',
  pageDescription:'',
}

SEO.propTypes = {
  pageDescription: PropTypes.string,
  heroImage: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
