//import { Link } from "gatsby"
import React from "react"
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fab)



function Footer() {
  return (
    <footer>
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4 social-media">
              <div className="d-flex justify-content-lg-start justify-content-center bd-highlight">        
                  <a href="https://www.instagram.com/510gallery/" aria-label="instagram link">
                    <FontAwesomeIcon icon={["fab", "instagram"]} />
                  </a>
                  <a href="https://www.pinterest.com/jabercrombia0631" aria-label="pinterest link">
                    <FontAwesomeIcon icon={["fab", "pinterest"]} />
                  </a>
                  <a href="https://www.linkedin.com/in/justin-abercrombia/" aria-label="linkedin link">
                    <FontAwesomeIcon icon={["fab", "linkedin"]} />
                  </a>  
              </div>
            </div>
            <div className="col-12 col-lg-4">
              Copyright &copy; {new Date().getFullYear()} | <a href="/">jabercrombia</a>
            </div>
            <div className="col-12 col-lg-4">

            </div>
          </div>
        </div>
      </div>
    </footer>


  )
}


export default Footer
